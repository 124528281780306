import React from 'react';
import * as styles from './BlogSplit.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';

export default function BlogSplit ({ data }) {
    return (
        <section className={styles.blogSplitWrapper}> 
            <div className={styles.layout}>
                    <div className= {styles.splitWrap}>
                        <div className= {styles.splitTop}>
                            <div className= {styles.contentWrap}>
                                <h5>{data.topHeader}</h5>                 
                                <p>{data.topContent}</p>
                            </div>
                            <div className= {styles.imageWrap}>
                                {data.topImage &&
                                        <div>
                                            <GatsbyImage
                                                alt={data.topHeader}
                                                image={data.topImage.asset.gatsbyImageData}></GatsbyImage>
                                        </div>
                                    }
                            </div>
                        </div>

                   
                        <div className= {styles.splitBottom}>
                            <div className= {styles.contentWrap}>
                                <h5>{data.bottomHeader}</h5>
                                <p>{data.bottomContent}</p>
                            </div>
                            <div className= {styles.imageWrap}>
                                {data.bottomImage &&
                                            <div>
                                                <GatsbyImage
                                                    alt={data.bottomHeader}
                                                    image={data.bottomImage.asset.gatsbyImageData}></GatsbyImage>
                                            </div>
                                        }
                            </div>
                        </div>
                    </div>

            </div>
        </section>
    )
}