import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import ResourceBuilder from '../components/resourceBuilder'

export const query = graphql`
  query ResourceTemplateQuery($id: String!) {
    page: sanityResources(id: {eq: $id}) {
      id
      slug {
        current
      }
      ...resourceBuilder
    }
  }
`

const PageTemplate = props => {
  const {data} = props
  const {page} = data
  const {resourceBuilder} = page

  return (
    <Layout>
      <ResourceBuilder sections={resourceBuilder} />
    </Layout>
  )
}

export default PageTemplate
