import React from 'react';
import BlockquoteHero from "./pagebuilderComponents/BlockquoteHero/BlockquoteHero";
import Faqs from './pagebuilderComponents/Faqs/Faqs';
import PageBanner from './pagebuilderComponents/pagebanner/pageBanner';
import ItemGrid from './pagebuilderComponents/itemGrid/itemGrid';
import TextAndPhotoMasthead from "./pagebuilderComponents/TextAndPhotoMasthead/TextAndPhotoMasthead";
import Textblock from './pagebuilderComponents/textblock/textblock';
import Events from './pagebuilderComponents/events/events'
import Event from './pagebuilderComponents/events/event'
import IconSection from './pagebuilderComponents/iconsection/iconSection';
import TeamSection from './pagebuilderComponents/teamSection/teamSection';
import ValuesSection from './pagebuilderComponents/valuesSection/valuesSection';
import PhotoAside from './pagebuilderComponents/photoAside/photoaside';
import BottomCta from './pagebuilderComponents/bottomCta/bottomCta';
import Updates from './pagebuilderComponents/getUpdates/getUpdates';
import MediaSplit from './pagebuilderComponents/mediaSplit/mediaSplit';
import Spacer from './pagebuilderComponents/spacer/spacer'
import Journey from './pagebuilderComponents/journey/journey';
import PersonList from './pagebuilderComponents/PersonList/PersonList';
import VideoHero from './pagebuilderComponents/videoHero/VideoHero';
import JourneyStages from './pagebuilderComponents/journeyStages/JourneyStages';
import CtaSection from './pagebuilderComponents/CtaSection/CtaSection';
import VideoCards from './pagebuilderComponents/VideoCards/VideoCards';
import BlogSplit from './pagebuilderComponents/blogSplit/BlogSplit';
import FileDl from './pagebuilderComponents/fileDl/FileDl';

export default function ResourceBuilder(data) {
  const {sections} = data

  // Bootstrap Pagebuilder Components
  const resourceBuilderComponents = {
    blockquoteHero: BlockquoteHero,
    faqs: Faqs,
    pageBanner: PageBanner,
    itemgrid: ItemGrid,
    textAndPhotoMasthead: TextAndPhotoMasthead,
    textBlock: Textblock,
    events: Events,
    iconSection: IconSection,
    teamSection: TeamSection,
    valuesSection: ValuesSection,
    photoaside: PhotoAside,
    bottomcta: BottomCta,
    updates: Updates,
    mediaSplit: MediaSplit,
    spacer: Spacer,
    journey: Journey,
    personList: PersonList,
    videoHero: VideoHero,
    journeyStages: JourneyStages,
    ctaSection: CtaSection,
    videoCards: VideoCards,
    blogSplit: BlogSplit,
    fileDl: FileDl,
    event: Event,
  }

  const pageSections = sections.map(section => {
    if(section) {
      // Get the type of Page Builder Section
      const sectionType = section._type

      // If we have a component for this page builder section, then render
      if(resourceBuilderComponents.hasOwnProperty(sectionType)) {
        return React.createElement(resourceBuilderComponents[sectionType], {
          key: section._key,
          data: section
        })
      }

      return null
    }

    // If we don't have a component for this, return null so that nothing renders
    return null
  })

  return (
    <div>
        {pageSections}
    </div>
  );
}


